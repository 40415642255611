import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyCCdFGVsrqbwjIbMsXJ-3HJXn1s0vCH2vQ",
    authDomain: "mystino-app.firebaseapp.com",
    databaseURL: "https://mystino-app.firebaseio.com",
    projectId: "mystino-app",
    storageBucket: "mystino-app.appspot.com",
    messagingSenderId: "153630493361",
    appId: "1:153630493361:web:35020ce541e3cf716db090"
};

export const createUserProfileDocument = async (userAuth, additionalData) => {
    if(!userAuth) return;

    const userRef = firestore.doc(`users/${userAuth.uid}`); // este o referinta
    const snapShot = await userRef.get(); //reprezinta datele

    if (!snapShot.exists) {
        const { email } = userAuth;
        const createdAt = new Date();

        try{
            await userRef.set({
                email,
                createdAt,
                ...additionalData
            })
        }catch (error) {
            console.log('error creating user', error.message())
        }
    }

    return userRef;
}

firebase.initializeApp(config);

export const  auth = firebase.auth();
export const firestore = firebase.firestore();

const twitterProvider = new firebase.auth.TwitterAuthProvider();
twitterProvider.setCustomParameters({ prompt: 'select_account'});
export const signInWithTwitter = () => auth.signInWithPopup(twitterProvider);

export default firebase;
