import React from 'react'
import {Link, withRouter} from "react-router-dom";
import logoNew from '../assets/imgs/logo-new.png'
import logoWhiteNew from '../assets/imgs/logo-white-new.png'

const Navbar = ({ currentUser, loginButton, registerButton, infoButton, signOut, location }) => {
    let logoPages =  <Link to={`/`}><img className='logo' src={logoNew} alt='logo'/></Link>;
    let logoGame =  <Link to={`/`}><img className='logo-white' src={logoWhiteNew} alt='logo'/></Link>;

  return (
    <div className={ location.pathname.includes('/game/') ? 'navbar__container game-nav' : 'navbar__container' }>
        <div className="row">
            <div className="logo-container col-sm-6 col-md-6 col-xl-6">
                { location.pathname.includes('/game/') ? logoGame : logoPages }
            </div>
            {
                currentUser ?
                    <div className="col-sm-6 col-md-6 col-xl-6 button-container mt-1">
                        <div className="d-inline-block">
                            <a className='button-login btn-log' onClick={() => signOut()}>ログアウト</a>
                        </div>
                    </div>
                    :
                    <div className="col-sm-6 col-md-6 col-xl-6 button-container">
                        <div className="register-container">{registerButton}</div>
                        <div className="d-inline-block">{loginButton}</div>
                    </div>
            }
        </div>

    </div>
  )
}

export default withRouter(Navbar)
