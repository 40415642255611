import React from 'react'
import {Link} from "react-router-dom";
import {FaTimes} from 'react-icons/fa'


const BackButton = () => {
  return (
      <Link to={`/`} className="close-game"><FaTimes/></Link>
  )
}

export default BackButton
