import React, {Component} from 'react'
import {Button, Modal, Form} from 'react-bootstrap'
import {FaTwitterSquare} from 'react-icons/fa'
import UserIcon from '../../assets/imgs/user.png'

import {auth, signInWithTwitter} from "../../firebase/firebase.utils";

const regExp = RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

class ResetPasswordModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resetSuccess: false,
            email: '',
            isError: {
                email: ''
            }
        }
    }

    formValChange = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let isError = {...this.state.isError};

        switch (name) {
            case "email":
                isError.email = regExp.test(value) ? "" : "メールアドレスの形式が正しくありません";
                break;
            default:
                break;
        }

        this.setState({
            isError,
            [name]: value
        })
    };

    onSubmit = async e => {
        e.preventDefault();
        let isError = {...this.state.isError};
        const {email} = this.state;

        if (email === "") {
            isError.email = "入力してください"
        } else {
            try {
                await auth.sendPasswordResetEmail(email)
                this.setState({
                    resetSuccess: true
                })
            } catch (error) {
                if (error.code === "auth/user-not-found") {
                    isError.email = "該当するユーザー履歴がありません。ユーザー情報が削除されている可能性がございます。";
                } else {
                    isError.email = "エラーが発生しました。再度お試しください。";
                }
            }
        }

        this.setState({
            isError
        })
    };

    onSubmitTwitter = () => {
        signInWithTwitter();
        this.props.onHide();
    }

    render() {
        const {setRegisterModal, ...otherProps} = this.props;
        const {isError} = this.state;

        return (
            <Modal
                {...otherProps}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-register"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                {!this.state.resetSuccess && <Modal.Body>
                    <h2 className={'title-modal'}>Reset password</h2>
                    <Form onSubmit={this.onSubmit} noValidate>
                        <Form.Group>
                            <Form.Control type="email" name="email"
                                          defaultValue={this.state.email}
                                          onBlur={this.formValChange} placeholder="メールアドレス"
                                          className={isError.email.length > 0 ? "is-invalid form-control input-modal" : "form-control input-modal"}
                            />
                            {isError.email.length > 0 && (
                                <span className="invalid-feedback">{isError.email}</span>
                            )}
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="primary" type="submit" className="button-register">
                                メールを送信
                            </Button>
                            <Form.Text className="text-muted f-10">
                                まだアカウントをお持ちではないですか？ <a href={'#'} onClick={setRegisterModal}>今すぐ新規登録</a>
                            </Form.Text>
                        </Form.Group>
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <a href="#" onClick={this.onSubmitTwitter} className="login-twitter"><span className="text-twitter">Log in with Twitter</span> <span className='i-twitter'><FaTwitterSquare/></span></a>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                }

                {this.state.resetSuccess && <Modal.Body>
                    <h4>パスワードをリセットするためのメールを確認してください</h4>
                </Modal.Body>
                }
            </Modal>
        );
    }
}

export default ResetPasswordModal


