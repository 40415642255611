import React, {Component} from 'react'
import {Button, Modal, Form} from 'react-bootstrap'
import {FaTwitterSquare} from 'react-icons/fa'
import UserIcon from '../../assets/imgs/user.png'

import {auth, signInWithTwitter} from "../../firebase/firebase.utils";

const regExp = RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

class LoginModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            isError: {
                email: '',
                password: ''
            }
        }
    }

    formValChange = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let isError = {...this.state.isError};

        switch (name) {
            case "email":
                isError.email = regExp.test(value) ? "" : "メールアドレスの形式が正しくありません";
                break;
            case "password":
                isError.password = value.length < 6 ? "最低6文字以上" : "";
                break;
            default:
                break;
        }

        this.setState({
            isError,
            [name]: value
        })
    };

    onSubmit = async e => {
        e.preventDefault();
        let isError = {...this.state.isError};
        const { email, password } = this.state;

        if(email === ""){
            isError.email = "入力してください"
        }

        if(password === ""){
            isError.password = "入力してください"
        }

        try {
            await auth.signInWithEmailAndPassword(email, password);
            this.setState({
                email: '',
                password: ''
            })
            this.props.onHide();

        } catch (error) {
            if (error.code === "auth/user-not-found") {
                isError.email = "該当するユーザー履歴がありません。ユーザー情報が削除されている可能性がございます。";
            }else if(error.code === "auth/wrong-password"){
                isError.email = "パスワードが無効か、またはパスワードを持っていないユーザーです。";
            }else{
                isError.email = "エラーが発生しました。再度お試しください。";
            }
        }

        this.setState({
            isError
        })
    };

    onSubmitTwitter = () => {
        signInWithTwitter();
        this.props.onHide();
    }

    render() {
        const {setRegisterModal, setResetPasswordModal, ...otherProps} = this.props;
        const {isError} = this.state;

        return (
            <Modal
                {...otherProps}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-register"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <h2 className='title-modal'>Log in to play!</h2>
                    <Form onSubmit={this.onSubmit} noValidate>
                        <Form.Group>
                            <Form.Control type="email" name="email"
                                          defaultValue={this.state.email}
                                          onBlur={this.formValChange} placeholder="Email address"
                                          className={isError.email.length > 0 ? "is-invalid form-control input-modal" : "form-control input-modal"}
                            />
                            {isError.email.length > 0 && (
                                <span className="invalid-feedback">{isError.email}</span>
                            )}
                        </Form.Group>

                        <Form.Group>
                            <Form.Control type="password" name="password"
                                          defaultValue={this.state.password}
                                          className={isError.password.length > 0 ? "is-invalid form-control input-modal" : "form-control input-modal"}
                                          onBlur={this.formValChange}
                                          placeholder="Password"/>
                            {isError.password.length > 0 && (
                                <span className="invalid-feedback">{isError.password}</span>
                            )}
                        </Form.Group>

                        <div className="row">
                            <div className="col-md-6 align-self-center">
                                <a href="#" onClick={setResetPasswordModal} className="forgot-pass">Forgot your password?</a>
                            </div>
                            <div className="col-md-6 text-right">
                                <button type="submit" className="login-btn">Log in</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <a href="#" onClick={this.onSubmitTwitter} className="login-twitter"><span className="text-twitter">Log in with Twitter</span> <span className='i-twitter'><FaTwitterSquare/></span></a>
                            </div>
                        </div>
                        <div className="row row-out">
                            <div className="col-md-6"><p className="account-out">Don't have<br/> an account yet?</p></div>
                            <div className="col-md-6"> <a className="link-join"
                                                          onClick={setRegisterModal}>Join Wunderino!</a></div>


                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default LoginModal


