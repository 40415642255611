import React from 'react';
import {Link} from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import GameProducer from '../components/GameProducer';
import language from '../assets/language';
import games from '../assets/social-games';
import headerLoginImage from '../assets/imgs/hero_image.jpg';
import headerRegisterImage from '../assets/imgs/header_signup.jpg';
import {Helmet} from "react-helmet";
import {auth} from "../firebase/firebase.utils";
import {FaqScript} from '../components/FaqScript';
import {ReadMore} from '../components/ReadMore';
import {Faq} from '../components/Faq';

const gameProducers = [
    {
        image: require('../assets/imgs/game_producers/yggdrasil.png'),
        title: 'NETENT',
        link: '/netent'
    },
    {
        image: require('../assets/imgs/game_producers/netent.png'),
        title: 'Evolution Gaming',
        link: '/evolution'
    },
    {
        image: require('../assets/imgs/game_producers/playngo.png'),
        title: 'QUICKSPIN',
        link: '/quickspin'
    },
    {
        image: require('../assets/imgs/game_producers/quickspin.png'),
        title: "Play'n Go",
        link: '/playgo'
    },
    {
        image: require('../assets/imgs/game_producers/microgaming.png'),
        title: 'Blaze of Ra',
        link: 'blazeofra'
    }
]

class Homepage extends React.Component {
    owlCarouselOptions = {
        items: 5,
        margin: 5,
        stagePadding: 20,
        nav: false,
        rewind: true,
        autoplay: false,
        responsive: {
            0: {
                items: 2,
                nav: false
            },
            720: {
                items: 3,
                nav: false
            },
            1000: {
                items: 5,
                nav: false
            },
        },
    }

    owlCarouselGamesOptions = {
        items: 10,
        margin: 20,
        // stagePadding: 20,
        nav: true,
        loop  : true,
        rewind: true,
        autoplay: false,
        responsive: {
            0: {
                items: 2,
                nav: true
            },
            500: {
                items: 3,
            },
            700: {
                items: 4,
                nav: true
            },
            1000: {
                items: 5,
                nav: true
            },
        },
    }

    constructor(props) {
        super(props);

        this.state = {
            gameList: games,
            errorMessage: "",
            limit: 15,
            showLoadMoreButton: true,
            numberOfGames: 0
        }

        this.loadMore = this.loadMore.bind(this);

    }



    loadMore = (e) => {
        e.preventDefault();

        if (this.state.limit + 25 >= this.state.gameList.length) {
            this.setState({showLoadMoreButton: false})
        }

        this.setState({limit: this.state.limit + 25})
    }

    //helpere
    showGames = (playAllGames) => {
        return this.state.gameList.slice(0, this.state.limit).map((game, index) => {
            let link;
            let gameBlur = '';

            if (playAllGames !== true) {
                if (game.canPlay === true) {
                    link = <Link to={`/game/` + game.id} className="play2 button">Play</Link>
                } else {
                    if (localStorage['isReturnVisitor']) {
                        link = <div className='div-homepage-register'><a className='button-register'
                                                                         onClick={this.props.showModal}>Sign up and PLAY</a>
                        </div>
                    } else {
                        link = <div className='div-homepage-register'><a className='button-register'
                                                                         onClick={this.props.showModal}>新規登録してプレイ</a>
                        </div>
                    }

                    gameBlur = 'game-blur'
                }
            } else {
                link = <Link to={`/game/` + game.id} className="play2 button">Play</Link>
            }

            return (
                <div key={index}>
                    {(playAllGames !== false || game.canPlay === true) ?
                    <div  className='game__card'>
                        <Link to={`/game/` + game.id} className='game-card-link'>
                            <img className={"game__card--image " + gameBlur} src={"https:" + game.thumbnailUrl} alt=''/>
                            <p className='game__card--title'>{game.name}</p>
                        </Link>
                        <p className='game__card--subtitle'>{game.shortDescription}</p>
                        {link}
                    </div>
                    :  <div className='game__card'>
                            <img className={"game__card--image " + gameBlur}  src={"https:" + game.thumbnailUrl} alt=''/>
                            <p className='game__card--title'>{game.name}</p>
                            <p className='game__card--subtitle'>{game.shortDescription}</p>
                            {link}
                        </div> }
                </div>
            )
        })
    }
    showFeaturedGames = (playAllGames) => {
        return this.state.gameList.slice(0, this.state.limit).map((game, index) => {

            if(index > 4){
                return;
            }

            let link;
            let gameBlur = '';

            if (playAllGames !== true) {
                if (game.canPlay === true) {
                    link = <Link to={`/game/` + game.id} className="play2 button">Play</Link>
                } else {
                    if (localStorage['isReturnVisitor']) {
                        link = <div className='div-homepage-register'><a className='button-register'
                                                                         onClick={this.props.showModal}>Sign up and PLAY</a>
                        </div>
                    } else {
                        link = <div className='div-homepage-register'><a className='button-register'
                                                                         onClick={this.props.showModal}>新規登録してプレイ</a>
                        </div>
                    }

                    gameBlur = 'game-blur'
                }
            } else {
                link = <Link to={`/game/` + game.id} className="play2 button">Play</Link>
            }


            return (
                <div key={index}>
                    {(playAllGames !== false || game.canPlay === true) ?
                        <div  className='game__card'>
                            <Link to={`/game/` + game.id} className='game-card-link'>
                                <img className={"game__card--image " + gameBlur} src={"https:" + game.thumbnailUrl} alt=''/>
                                <p className='game__card--title'>{game.name}</p>
                            </Link>
                            <p className='game__card--subtitle'>{game.shortDescription}</p>
                            {link}
                        </div>
                        :  <div className='game__card'>
                            <img className={"game__card--image " + gameBlur}  src={"https:" + game.thumbnailUrl} alt=''/>
                            <p className='game__card--title'>{game.name}</p>
                            <p className='game__card--subtitle'>{game.shortDescription}</p>
                            {link}
                        </div> }
                </div>

            )
        })
    }

    showGameProducers = () => {
        window.getSelection().empty()
        return gameProducers.map((elem, index) => (
            <GameProducer
                key={index}
                image={elem.image}
                link={elem.link}
            />
        ))
    }

    signOut = () => {
        auth.signOut();
    }

    render() {

        let playAllGames = true;
        let headerImage = headerRegisterImage;
        let CtaHeader = <a className="button-register" onClick={this.props.showModal}>{language.buttons.login}</a>
        let showMoreContent =
            <div className="text-center">
                <a href='#' onClick={this.loadMore} className='slideshow__container--button'>
                    {language.buttons.show_more}
                </a>
            </div>;
        // Cookies.set("test", "nice");


        if (!this.state.showLoadMoreButton) {
            showMoreContent = "";
        }

        headerImage = headerLoginImage;
        // CtaHeader = <div className={'header-text'}><p>jump <br/> into action<br/> for free</p><a className="button-register" onClick={this.props.showModal}>Sign up</a></div>
        CtaHeader = <div className={'header-text'}><h1>{language.homepage.cta}</h1><a className="button-register" href='#all-games'>{language.buttons.play_for_free}</a></div>

        return (
            <div className='homepage__container'>
                <Helmet>
                    <title>Freispiele ohne einzahlung: spielautomaten auf Wunderino</title>
                    <meta name={"description"} content={"Auf der Suche nach Freispielen ohne Einzahlung? Bei Wunderino.social können Sie spielautomaten Umsonst spielen, wann immer Sie wollen."} />
                    <link rel="canonical" href="https://wunderino.social"  />
                    <meta name="robots" content="index,follow" />
                    <script type="application/ld+json">
                        {JSON.stringify({"@context": "https://schema.org",
                            "@type": "Organization",
                            "name": "Wunderino.social",
                            "url": "https://wunderino.social/",
                            "logo": "https://wunderino.social/static/media/logo.png"})}
                    </script>
                </Helmet>

                {!this.props.currentUser &&
                <div className='slideshow__container'
                     style={{backgroundImage: `url(${headerImage})`}}
                >
                    {CtaHeader}
                </div>
                }

                <div className='bg__game__container'>
                    <h2 className={'subtitle'}>{language.homepage.recommended_free_games}</h2>
                    {/*<div className='game__container'>*/}
                        {/*{this.showFeaturedGames(playAllGames)}*/}
                    {/*</div>*/}
                    <div className='container-carousel'>
                        <OwlCarousel className='owl-theme' {...this.owlCarouselGamesOptions}>
                            {this.showFeaturedGames(playAllGames)}
                        </OwlCarousel>
                    </div>
                </div>

                {/*<div className='bg__game__container'>*/}
                    <h2 className={'subtitle color-black'}>{language.homepage.all_free_games}</h2>
                    <div className='game__container color-black' id='all-games'>
                        {this.showGames(playAllGames)}
                    </div>
                {/*</div>*/}
                {/*{showMoreContent}*/}

                <ReadMore />

                <Faq />

                <div className='gameproducers__container'>
                    <p className='gameproducers__container--title'>{language.homepage.games_providers}</p>
                    <OwlCarousel className='owl-theme' {...this.owlCarouselOptions}>
                        {this.showGameProducers()}
                    </OwlCarousel>
                </div>
                {/*<CookieConsent*/}
                {/*    // onAccept={() => {*/}
                {/*    //     alert("yay!");*/}
                {/*    // }}*/}
                {/*    // onDecline={() => {*/}
                {/*    //     alert("nay!");*/}
                {/*    // }}*/}
                {/*    debug={false}*/}
                {/*    enableDeclineButton*/}
                {/*    declineButtonText="Ich lehne ab"*/}
                {/*    buttonText="Ich verstehe"*/}
                {/*>*/}
                {/*    Wunderino.social und seine ausgewählten Partner verwenden Cookies, um zu analysieren,*/}
                {/*    wie Sie unsere Website nutzen, und Ihnen so die für Sie relevantesten Inhalte und Werbung anzubieten.{" "}*/}

                {/*</CookieConsent>*/}

                <script type="application/ld+json">
                    <FaqScript />
                </script>
            </div>
        )
    }
}

export default Homepage
