import React from 'react'
import { Helmet } from 'react-helmet'

const TermsConditions = () => (
    <div className="mb-5 terms-and-conditions">
        <Helmet>
            <title>Wunderino.social Privacy Policy</title>
            <link rel="canonical" href="https://wunderino.social/terms-and-conditions"/>
        </Helmet>

        <div className='gameplay__container bg-img'>
            <div className='container text-center' style={{fontSize: 24}}>
                <h1>Wunderino.social Privacy Policy</h1>
            </div>
        </div>

        <div className="container">
            <div className="row mb-5">
                <div className="col-lg-12 text-left ml-2">
                    <br/>
                    <br/>

                    <p>&nbsp;</p>
                    <ol>
                        <li><strong><u> General</u></strong></li>
                    </ol>
                    <p>&nbsp;</p>
                    <p>This page constitutes the Terms and Conditions (<strong>&ldquo;T&amp;Cs</strong><strong>&rdquo;</strong>) of Wunderino.social, and every individual registered user using the site must accept these T&amp;Cs without exception. These T&amp;Cs and any document expressly referred to therein, constitute the agreement and understanding between the Parties and govern the contractual relationship between us and yourself. Please read these T&amp;Cs carefully before making use of Wunderino.social (the <strong>&ldquo;Website&rdquo;</strong>) and make sure you understand them. In case of disagreement with these T&amp;Cs you should not use or continue using the Website. We also recommend that you familiarise yourself with our Privacy Policy.</p>
                    <p>&nbsp;</p>
                    <p><u>1.1</u></p>
                    <p>This Website is operated by Alcanada Media Partners Limited (<strong>"we"</strong>, <strong>"us"</strong> or <strong>"</strong><strong>Alcanada</strong><strong>"</strong>), a company registered under the laws of Gibraltar with registration number 114021 and with registered office at Suite 3, Second Floor, Icom House, 1/5 Irish Town, Gibraltar GX11 1AA.</p>
                    <p>&nbsp;</p>
                    <p><u>1.2</u></p>
                    <p>Alcanada reserves the right to modify these T&amp;Cs from time to time for a number of reasons, including to comply with applicable laws and regulations, as well as other regulatory requirements, consequently we may amend this agreement between you and Alcanada at any time. Nevertheless, whenever a substantial amendment is made to the T&amp;Cs, we will notify you via a notice on the Website upon account login. If you do not agree to be bound by the updated T&amp;Cs you should contact Customer Support if you would like to close your account and withdraw any available balance. You can easily identify whether these T&amp;Cs have changed by referring to the version number and the date of the current T&amp;Cs stated at the top of this page.</p>
                    <p>&nbsp;</p>
                    <p><u>1.3</u></p>
                    <p><em>1.3.1</em></p>
                    <p>These T&amp;Cs include and incorporate a number of additional terms, as follows:</p>
                    <p>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any rules for a game you choose to participate in (<strong>&ldquo;Rules&rdquo;</strong>);</p>
                    <p>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Our promotional terms which apply to specific promotions or offers from time to time (<strong>&ldquo;Promotional Terms&rdquo;</strong>); and</p>
                    <p>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Our Privacy Policy which sets out the terms on which we process any personal data we collect from you or that you provide to us.</p>
                    <p>&nbsp;</p>
                    <p><em>1.3.2</em></p>
                    <p>You understand and agree to be bound by the clauses contained in these T&amp;Cs as may be amended from time to time. You further acknowledge that by registering and using the Website or any other website that we may own from time to time, you are also bound by the Rules, Promotional Terms and Privacy Policy and it is deemed that you have read, understood and accepted in full.</p>
                    <p>&nbsp;</p>
                    <p><u>1.4</u></p>
                    <p>In the event of a conflict between these T&amp;Cs, and any of the Rules, the Promotional Terms and/or the Privacy Policy, the order or preference shall be as follows to the extent of any such conflict only:</p>
                    <p>1.4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the Rules;</p>
                    <p>1.4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the Promotional Terms;</p>
                    <p>1.4.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the Privacy Policy.</p>
                    <p>&nbsp;</p>
                    <ol start="2">
                        <li><strong><u> Obligations, Mandatory Requirements and Responsibilities</u></strong></li>
                    </ol>
                    <p><u>2.1</u></p>
                    <p>By accepting the T&amp;Cs and proceeding to register an account you declare and warrant that:</p>
                    <p>&nbsp;</p>
                    <p><em>2.1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </em></p>
                    <p>You must register personally, and you are solely responsible for your account details including but not limited to the username and password and/or any other means to access your account via the Website. In the case of suspicion that your account details may have been compromised, you must inform us and take appropriate action to prevent any unauthorized access to any part of your account or any fictitious funds held therein.</p>
                    <p>&nbsp;</p>
                    <p><em>2.1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </em></p>
                    <p>You are at least 20 years old or such higher minimum legal age of majority as stipulated in the laws of the jurisdiction applicable to your residence. It is a criminal offence to gamble whilst being underage. If we have reasonable grounds to believe that any account is being operated in breach of the foregoing, the account will be closed immediately.</p>
                    <p>&nbsp;</p>
                    <p><em>2.1.3</em></p>
                    <p>You may only open one account on the Website. If you open or attempt to open more than one account, for whatever reason, we may block or close any or all of your accounts at our discretion, including voiding any fictitious amounts placed in the duplicate accounts.</p>
                    <p>&nbsp;</p>
                    <p><em>2.1.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </em></p>
                    <p>You intend to participate on the Website on your own behalf and not on behalf of another person in your personal capacity for recreational and entertainment reasons only.</p>
                    <p>&nbsp;</p>
                    <p><em>2.1.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </em></p>
                    <p>You are aware that the right to access and use the Website may be considered illegal in certain territories. It is your sole responsibility to ensure that you are permitted to use our Website in your territory. The availability of the Website in any particular jurisdiction does not constitute an offer or invitation by us to use the services offered on the Website. We do not permit accounts to be opened by, or used from, customers based in the US, Netherlands, France, Dutch West Indies and Cura&ccedil;ao. We make no representation that materials on the Website or our services are appropriate, available or allowed in your jurisdiction. We are unable to provide any legal advice regarding this matter and we accept no responsibility whatsoever should an individual act in breach of any law or regulation in transacting with us in whichever manner.</p>
                    <p>&nbsp;</p>
                    <p>For the avoidance of doubt, access and registration from territories that do not feature on the registration menu is strictly prohibited (<strong>&ldquo;Prohibited Territories&rdquo;</strong>).</p>
                    <p>&nbsp;</p>
                    <p>Any attempt to circumvent this restriction, for example, by using a VPN, proxy or similar service that masks or manipulates the identification of your real location, or by otherwise providing false or misleading information regarding your citizenship, location or place of residence, or by using the Website through a third party or on behalf of a third party located in a Prohibited Territory is a breach of these T&amp;Cs and may constitute a criminal offence. If we have reasonable grounds to suspect that you are accessing the Website from any Prohibited Territory, we may close your account without any obligation to provide you with advance notice.</p>
                    <p>&nbsp;</p>
                    <p><em>2.1.6</em></p>
                    <p>You shall submit truthful, complete and accurate personal information as requested during registration and throughout the lifetime of your account including but not limited to your full name, home address, date of birth, email address, telephone number and relevant payment information. It is your sole responsibility to ensure that this obligation is upheld and information kept up-to-date. You are required to inform us as to any updates, as well as to keep up-to-date the mandatory information provided in the registration form in the event that such information changes.</p>
                    <p>&nbsp;</p>
                    <p><em>2.1.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </em></p>
                    <p>Where applicable, you shall be courteous to other customers using the Website, as well as support personnel employed by Alcanada and avoid rude or obscene comments. Use of the chat facility should strictly relate to our services and any queries related thereto. The non-observance of this term shall not be tolerated under any circumstance.</p>
                    <p>&nbsp;</p>
                    <ol start="3">
                        <li><strong><u> Account</u></strong></li>
                    </ol>
                    <p><u>3.1</u></p>
                    <p>In order for you to be able to play you must first register personally and open an account (the <strong>&ldquo;Account&rdquo;</strong>). You need to be registered on our Website in order to participate in any of the games we offer. Alcanada reserves the right to refuse to register or to open an Account at its sole discretion.</p>
                    <p>&nbsp;</p>
                    <p><u>3.2</u></p>
                    <p>Only one Account for each household, IP address and device is allowed. If we identify any user with more than one Account, we reserve the right to close any duplicate account(s).</p>
                    <p>&nbsp;</p>
                    <p><u>3.3</u></p>
                    <p>As part of the registration process you will have to choose your username and password for your login into the Website. It is your sole and exclusive responsibility to ensure that your login details are kept securely. You must not disclose your login details to anyone. We are not responsible for any abuse or misuse of your Account by third parties due to your disclosure, whether intentional or accidental, whether active or passive, of your login details to any third party. Any actions implemented using your login details will be considered as being implemented by the User and any liability arising from such use shall be strictly yours. If third parties are aware of your login details, it is your sole responsibility to inform us and it is your sole responsibility to change such login details.</p>
                    <p>&nbsp;</p>
                    <p><u>3.4</u></p>
                    <p>You can log into your Account at any time where you can also view your account history. Should you notice any mistakes, you should immediately notify Alcanada so that it can be reviewed and rectified as necessary.</p>
                    <p>&nbsp;</p>
                    <p><u>3.5</u></p>
                    <p>No interest is payable on your Account balance irrespective of the fictitious amount held in your Account. Accordingly, you shall not in any way treat Alcanada as a financial institution.</p>
                    <p>&nbsp;</p>
                    <p><u>3.6</u></p>
                    <p>You understand and accept that Alcanada strictly prohibits cheating customers and customer collusion. Should we have reasonable suspicion that any cheating or collusion has occurred, we reserve the right to void any gameplay that we suspect has been made as a result of such behaviour and/or confiscate any fictitious funds on your Account and close your Account. Any customer who has reasonable grounds to suspect that another customer is taking an unfair advantage through cheating or collusion is to report the suspicion to Alcanada via email or live chat.</p>
                    <p>&nbsp;</p>
                    <p><u>3.7</u></p>
                    <p>Fictitious funds cannot be transferred from your Account to another Account or vice-versa.</p>
                    <p>&nbsp;</p>
                    <p><u>3.8</u></p>
                    <p>You agree not to try and hack the Website or alter its code in any way. You also agree not to use any robotic, automated, mechanical, electronic or other devices to make automated decisions in your use of the Website, whether such use is attempted or affected by you or by any third parties. Should we have reasonable suspicion that any such device is being used on the Website, or any such external resource that is designed to provide a customer with unfair advantage over other customers, we reserve the right to void any gameplay that we suspect has been made using such a device. We may also suspend the Account in question pending investigation into the matter and terminate the Account at our sole discretion. We consider the use of such devices to be tantamount to fraud and reserve the right not to refund any fictitious balance on the Account at the time of termination.</p>
                    <p>&nbsp;</p>
                    <p><u>3.9</u></p>
                    <p>Alcanada does not offer its services to customers who are identified as Politically Exposed Persons (<strong>&ldquo;PEPs&rdquo;</strong>). Should you be identified as a PEP at any stage, your Account will be closed. If you disagree with our determination of your PEP status, please contact us via email or live chat.</p>
                    <p>&nbsp;</p>
                    <ol start="4">
                        <li><strong><u> Game Specific Rules</u></strong></li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><u>4.1 </u></p>
                    <p>Certain provisions laid down in this section refer to the rules, terms and contractual provisions for specific games, which are found in the specific game tabs on the Site. The rules of such games form an integral part of these Terms and Conditions.</p>
                    <p>&nbsp;</p>
                    <p><u>4.2</u></p>
                    <p>You shall abide by any and all rules in the Games as set out in connection with the games and/or in the corresponding help files such as the house rules and the house policies.</p>
                    <p>&nbsp;</p>
                    <ol start="5">
                        <li><u> </u><strong><u>Warranties and Liability</u></strong></li>
                    </ol>
                    <p><u>&nbsp;</u></p>
                    <p><u>5</u><u>.1</u></p>
                    <p>Alcanada does not guarantee that the Website is and works flawlessly and without errors or that the Website and the games included on the Website will be accessible without interruptions or that the Website and the games are fit for purpose. No such warranties can be provided by Alcanada, whether express or implied.</p>
                    <p>&nbsp;</p>
                    <p><u>5</u><u>.2</u></p>
                    <p>Alcanada does not accept any liability for any failures or issues that arise due to your equipment, Internet connection or third-party provider. This shall also include your inability to play or to view or receive information in relation to certain particular games.</p>
                    <p>&nbsp;</p>
                    <p><u>5</u><u>.3</u></p>
                    <p>In the event of any interrupted, aborted or miscarried game rounds for any reason whatsoever, all the customers&rsquo; transactions shall be accurately recorded. Any unfinished game rounds are restored when returning to the game and in the event this is not possible Alcanada shall clean up such game rounds.</p>
                    <p>&nbsp;</p>
                    <p><u>5</u><u>.4</u></p>
                    <p>Alcanada reserves the right to make the Website and/or certain games temporarily unavailable at its own discretion and without giving prior notice to customers. We shall not be liable for any loss that you may incur as a result of such unavailability.</p>
                    <p>&nbsp;</p>
                    <p><u>5.5</u></p>
                    <p>Alcanada is not liable for any downtime, server disruptions, lagging or any technical or political disturbance to the game play. Fictitious refunds may be given solely at Alcanada&rsquo;s discretion.</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <ol start="6">
                        <li><strong><u>6</u></strong><strong><u>.</u></strong><strong><u> Limitation of Liability</u></strong></li>
                    </ol>
                    <p><u>6</u><u>.1</u></p>
                    <p>You acknowledge that the services offered on this Website are for entertainment purposes only. You are not requested to use our services and your participation is at your sole choice and discretion. You consequently enter the Website and participate in the games at your own risk. You claim that your interest in our services and in our Website is personal and not professional and that you have registered an account with us only for personal entertainment. Any other use of the services we provide is strictly prohibited.</p>
                    <p>&nbsp;</p>
                    <p><u>6</u><u>.2</u></p>
                    <p>Without prejudice to the generality of the preceding provision, Alcanada and its directors, employees and partners shall not be liable for any loss, costs, expenses or damages, whether direct, indirect, special, consequential, incidental or otherwise, arising in relation to your use of the Website or your participation in the games to the extent permitted by law. For the avoidance of doubt Alcanada&rsquo;s liability for any error or malfunction included in the games is limited to replacement.</p>
                    <p>&nbsp;</p>
                    <p><u>6.</u><u>3</u></p>
                    <p>All information displayed on our Website is provided for information purposes only and not intended to constitute professional advice of any kind. Neither Alcanada nor any of its independent providers is liable for any information errors, incompleteness, inaccuracy or delays, or for any actions taken in reliance on information contained therein.</p>
                    <p>&nbsp;</p>
                    <ol start="7">
                        <li><u></u><strong><u> Intellectual Property</u></strong></li>
                    </ol>
                    <p><u>7</u><u>.1</u></p>
                    <p>All intellectual property rights (<strong>&ldquo;IPR&rdquo;</strong>) in the Website are licensed to Alcanada or a third-party software provider. Your use of the Website does not give you any rights in any IPR on the Website or in the games. IPR include any and all intellectual property rights of all types and nature whatsoever including without limitation, patent, copyright, design rights, trademarks, database rights, applications for any of the above, moral rights, know-how, trade secrets, domain names, URL, tradenames or any other intellectual or industrial property rights (and any licences in connection with the same), whether or not registered or capable of registration, and whether subsisting in any specific country or countries or any other part of the world.</p>
                    <p>&nbsp;</p>
                    <p><u>7</u><u>.2</u></p>
                    <p>You may not within the limits prescribed by applicable laws: &nbsp;</p>
                    <p>&nbsp;</p>
                    <p>7<em>.2.1</em></p>
                    <p>copy, distribute, publish, reverse engineer, decompile, disassemble, modify or translate the software and/or the Website or make any attempt to access the source code to create derivative works of the source code of the software and/or the Website, or otherwise;</p>
                    <p>&nbsp;</p>
                    <p>7<em>.2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </em></p>
                    <p>sell, assign, sublicense, transfer, distribute or lease the software;</p>
                    <p>&nbsp;</p>
                    <p>7<em>.2.3</em></p>
                    <p>make the software available to any third party through a computer network or otherwise;</p>
                    <p>&nbsp;</p>
                    <p>7<em>.2.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </em></p>
                    <p>export the software to any country (whether by physical or electronic means);</p>
                    <p>&nbsp;</p>
                    <p>7<em>.2.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </em></p>
                    <p>use the software in a manner prohibited by applicable laws or regulations; or</p>
                    <p>&nbsp;</p>
                    <p>7.<em>2.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </em></p>
                    <p>do anything that will harm or potentially harm the IPR or do anything that damages the image or reputation of Alcanada, its employees, directors, officials and consultants.</p>
                    <p>&nbsp;</p>
                    <p><u>7</u><u>.3</u></p>
                    <p>You warrant that any names or images used by you in connection with the Website or games (for example your username) shall not infringe the intellectual property, privacy or other rights of any third party or are abusive or offensive towards others. You hereby grant Alcanada a worldwide, irrevocable, transferable, royalty-free, sub-licensable licence to use such names and images for any purpose connected with the Website or games, subject to the terms of our Privacy Policy.</p>
                    <p>&nbsp;</p>
                    <ol start="8">
                        <li><strong><u>8</u></strong><strong><u>. Complaints</u></strong><strong><u> and Dispute Resolution</u></strong></li>
                    </ol>
                    <p><u>8</u><u>.1</u></p>
                    <p>You may contact our Customer Support team according to the instructions on the Website should you wish to make any complaints regarding our services. Alternatively, you may send us an email on <a href="mailto:hello@mystino.com">[-]</a>.</p>
                    <p>&nbsp;</p>
                    <p><u>8</u><u>.2</u></p>
                    <p>Complaints are handled by the Customer Support team and escalated within Alcanada in cases where the support personnel do not solve the case immediately. You shall be reasonably informed about the state of your complaint. We always endeavour to resolve complaints in the least time possible.</p>
                    <p>&nbsp;</p>
                    <ol start="9">
                        <li><u></u><strong><u> Miscellaneous</u></strong></li>
                    </ol>
                    <p><u>9</u><u>.1</u></p>
                    <p>If you breach any provision of our T&amp;Cs or should we have a reasonable ground to suspect that you have breached them, we reserve the right to not open, to suspend, or to close your Account, or&nbsp; withhold the payment of your fictitious winnings. The foregoing shall not exclude our right to take legal action against you as deemed appropriate in the circumstances.</p>
                    <p>&nbsp;</p>
                    <p><u>9.</u><u>2</u></p>
                    <p>These T&amp;Cs may be available in a number of languages for information purposes and ease of access. For the avoidance of doubt, the English language version of these T&amp;Cs shall prevail over any other language version made available.</p>
                    <p>&nbsp;</p>
                    <p><u>9</u><u>.3</u></p>
                    <p>We shall not be in breach of these T&amp;Cs nor liable for delay in performing or failure to perform, any of our obligations under these T&amp;Cs where any such delay or failures result from incidents, events or causes which are beyond our reasonable control.</p>
                    <p>&nbsp;</p>
                    <p><u>9.</u><u>4</u></p>
                    <p>If any provision of these T&amp;Cs is held to be illegal or unenforceable, such provision shall be severed from these T&amp;Cs and all other provisions shall remain in force and unaffected by any such severance.</p>
                    <p>&nbsp;</p>
                    <p><u>9</u><u>.5</u></p>
                    <p>These T&amp;Cs are governed by the laws of Gibraltar. You agree that any dispute, controversy or claim arising out of or in connection with these T&amp;Cs, or the breach, termination or invalidity thereof, shall be submitted to the exclusive jurisdiction of the Gibraltar courts.</p>
                </div>
            </div>
            {/*<BackButton/>*/}
        </div>
    </div>
)

export default TermsConditions
