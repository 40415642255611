import React, {Component} from 'react'
import {Button, Modal, Form} from 'react-bootstrap'
import {FaTwitterSquare, FaEye, FaEyeSlash} from 'react-icons/fa'
import UserIcon from '../../assets/imgs/user.png'

import {auth, createUserProfileDocument, signInWithTwitter} from "../../firebase/firebase.utils";
import {Link} from "react-router-dom";

const regExp = RegExp(
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

const formValid = ({isError, ...rest}) => {
    let isValid = false;

    Object.values(isError).forEach(val => {
        if (val.length > 0) {
            isValid = false
        } else {
            isValid = true
        }
    });

    Object.values(rest).forEach(val => {
        if (val === null) {
            isValid = false
        } else {
            isValid = true
        }
    });

    return isValid;
};


class RegisterModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            terms: true,
            news: true,
            showPassword: false,
            isError: {
                email: '',
                password: '',
                terms: '',
                news: '',
                terms_news: '',
            }
        }
    }

    formValChange = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let isError = {...this.state.isError};

        switch (name) {
            case "email":
                isError.email = regExp.test(value) ? "" : "メールアドレスの形式が正しくありません";
                break;
            case "password":
                isError.password = value.length < 6 ? "最低6文字以上" : "";
                break;
            default:
                break;
        }

        this.setState({
            isError,
            [name]: value
        })
    };

    handleCheckbox = event => {
        let isError = {...this.state.isError};
        const target = event.target;
        const value = target.checked;
        const name = target.name;

        if (value === true) {
            isError.news = '';
            this.setState({
                isError
            });
        }

        this.setState({
            [name]: value
        });
    }

    tooglePasswordVisibility = () => {
        const {showPassword} = this.state;

        this.setState({showPassword: !showPassword})
    }

    onSubmit = async e => {
        e.preventDefault();
        const {email, password, terms, news, isError} = this.state;
        let isValid = true;

        if (email === "") {
            isError.email = "入力してください"
            isValid = false
        }

        if (password === "") {
            isError.password = "入力してください"
            isValid = false
        }

        if (terms === false || news === false) {
            isError.news = "上記に同意してください"
            isValid = false
        }

        if (!isValid) {
            this.setState({
                isError
            })
        }
        if (formValid(this.state) && isValid) {

            try {
                const {user} = await auth.createUserWithEmailAndPassword(email, password)
                await createUserProfileDocument(user)

                this.state = {
                    email: '',
                    password: '',
                    terms: '',
                    isError: {
                        email: '',
                        password: '',
                        terms: '',
                    }
                }

                this.props.onHide(); // ascund modalul dupa register
                this.props.showRegisterSuccessModal();
            } catch (error) {

                if (error.code === "auth/email-already-in-use") {
                    isError.email = "既に登録されているメールアドレスです。ログインをしてください。"
                }else{
                    isError.email = "エラーが発生しました。再度お試しください。"
                }

                this.setState({
                    isError
                })
            }
        }
    };

    onSubmitTwitter = () => {
        signInWithTwitter();
        this.props.onHide();
    }

    render() {
        const {setLoginModal, show, onHide} = this.props;
        const {isError, showPassword} = this.state;


        return (
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-register"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <h2 className='title-modal'>Register and play</h2>
                    <Form onSubmit={this.onSubmit} noValidate>
                        <Form.Group>
                            <Form.Control type="email" name="email"
                                          defaultValue={this.state.email}
                                          onBlur={this.formValChange} placeholder="Email address"
                                          className={isError.email.length > 0 ? "is-invalid form-control input-modal" : "form-control input-modal"}
                            />
                            {isError.email.length > 0 && (
                                <span className="invalid-feedback">{isError.email}</span>
                            )}
                        </Form.Group>
                        <Form.Group className="mt-24">
                            <i className="password-icon" onClick={this.tooglePasswordVisibility}>
                                {showPassword ? <FaEyeSlash size="25px"/> : <FaEye size="25px"/> }
                            </i>
                            <Form.Control type={showPassword ? 'text' : 'password'}
                                          name="password"
                                // defaultValue={this.state.password}
                                          className={isError.password.length > 0 ? "is-invalid form-control input-modal" : "form-control input-modal"}
                                          onBlur={this.formValChange}
                                          placeholder="Password"/>

                            {isError.password.length > 0 && (
                                <span className="invalid-feedback">{isError.password}</span>
                            )}
                        </Form.Group>
                        <Form.Group className={isError.terms.length > 0 ? "" : "mb-0"} controlId={"terms"}>
                            <Form.Check type="checkbox"
                                        label="I have read and agree to the terms of use."
                                        name="terms"
                                        className={isError.terms.length > 0 ? "is-invalid form-check-modal mt-m" : "form-check-modal mt-m"}
                                        onClick={this.handleCheckbox}
                                        defaultChecked={true}
                            />
                            {isError.terms.length > 0 && (
                                <span className="invalid-feedback">{isError.terms}</span>
                            )}
                        </Form.Group>
                        <Form.Group style={{marginTop:'10px'}} className={isError.news.length > 0 ? "" : "mb-0"} controlId={"news"}>
                            <Form.Check type="checkbox"
                                        label="We may notify you of future campaigns, new products, services and event information to the
                                        details provided by us or a trusted third party."
                                        name="news"
                                        className={isError.news.length > 0 ? "is-invalid form-check-modal" : "form-check-modal"}
                                        onClick={this.handleCheckbox}
                                        defaultChecked={true}
                            />
                            {isError.news.length > 0 && (
                                <span className="invalid-feedback">{isError.news}</span>
                            )}
                        </Form.Group>

                        {/*<label className="check-container" onClick={this.handleCheckbox}><span className="text-checkbox">I have read and agree to the terms of use.</span>*/}
                        {/*<input type="checkbox" name="terms" id="terms" />*/}
                        {/*<span className="checkmark"></span>*/}
                        {/*</label>*/}

                        {/*<label className="check-container" onClick={this.handleCheckbox}>*/}
                        {/*<span className="text-checkbox">We may notify you of future campaigns, new products, services and event information to the*/}
                        {/*details provided by us or a trusted third party.</span>*/}
                        {/*<input type="checkbox" name="news" id="notify" />*/}
                        {/*<span className="checkmark"></span>*/}
                        {/*</label>*/}

                        <div className="row">
                            <div className="col-md-12 text-right">
                                <button type="submit" className="login-btn">Sign Up</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <a href="#" className="login-twitter" onClick={this.onSubmitTwitter}><span className="text-twitter">Log in with Twitter</span> <span className='i-twitter'><FaTwitterSquare/></span></a>
                            </div>
                        </div>
                        <div className="row row-out">
                            <div className="col-md-7"> <p className="account-out">Do you already<br/> have an account?</p></div>
                            <div className="col-md-5"><a className="link-join text-inherit" onClick={setLoginModal}>Login Now</a></div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default RegisterModal


