import React, { Component } from 'react'
import './assets/scss/main.scss'


import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Navbar from './layout/Navbar'
import Footer from './layout/Footer'
import Homepage from './pages/Homepage'
import TermsConditions from './pages/TermsConditions'
import PrivacyPolicyEn from "./pages/PrivacyPolicyEn";
import NotFound from './pages/NotFound'
import Gamepage from './pages/Gamepage'
import CustomModal from './components/modals/CustomModal'


import { auth, createUserProfileDocument } from "./firebase/firebase.utils";


class App extends Component {
    constructor() {
        super();

        this.state = {
            showCustomModal: false,
            modalActive: 'login-modal',
            currentUser: null,
        };
    }

    unsubscribeFromAuth = null;


    componentDidMount() {

       this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => { // este open subscription
           if(userAuth){
               const userRef = await createUserProfileDocument(userAuth);

               await userRef.onSnapshot( snapshot => {
                   this.setState({
                       currentUser: {
                           id: snapshot.id,
                           ...snapshot.data()
                       }
                   });

                   localStorage['user'] = JSON.stringify({
                       id: snapshot.id,
                       ...snapshot.data()
                   });

                   localStorage['isReturnVisitor'] = true;
               })
           }else{
               this.setState({currentUser: userAuth}) // userAuth e null
               localStorage.removeItem('user');
           }
        })

        // setTimeout(function() {
        //     if(this.state.currentUser == null){
        //         this.showInfoModal()
        //     }
        // }.bind(this), 180000);
    }

    componentWillUnmount() { // inchide subscription si sesiunea
        this.unsubscribeFromAuth();
    }

    closeModal = () => {
        this.setState({ showCustomModal: false })
    }

    showLoginModal = () => {
        this.setState({
            showCustomModal: true,
            modalActive: 'login-modal'
            // modalActive: 'register-success'
        })
    }

    showRegisterModal = () => {
        this.setState({
            showCustomModal: true,
            modalActive: 'register-modal'
        })
    }

    showInfoModal = () => {
        if(this.state.showCustomModal === false){ //afisare pop-up info doar daca nu este deja afisat login/register
            this.setState({
                showCustomModal: true,
                modalActive: 'info-modal'
            })
        }
    }

    showRegisterSuccessModal = () => {
        this.setState({
            showCustomModal: true,
            modalActive: 'register-success'
        })
    }

    showResetPasswordModal = () => {
        this.setState({
            showCustomModal: true,
            modalActive: 'reset-password'
        })
    }

    signOut = () => {
        auth.signOut();
    }

    render() {
        let loginButton, registerButton;

        // if(localStorage['isReturnVisitor']){
        //     loginButton =   <a className='button-login btn-log-active' onClick={this.showRegisterModal} >  Sign up </a>;
        //     registerButton = <a className='button-login btn-log' style={{padding: '7px 15px'}} onClick={this.showLoginModal}>  Login </a>
        // }else{
        //     loginButton =   <a className='button-login btn-log' onClick={this.showRegisterModal} >  Sign up </a>;
        //     registerButton = <a className='button-register' onClick={this.showLoginModal}>  Login </a>
        // }

        return (
            <Router>
                    <Navbar currentUser={this.state.currentUser}
                            loginButton={loginButton}
                            registerButton={registerButton}
                            signOut={this.signOut}
                    />

                    <CustomModal
                        show={this.state.showCustomModal}
                        modalActive={this.state.modalActive}
                        onHide={this.closeModal}
                        showRegisterModal={this.showRegisterModal}
                        showLoginModal={this.showLoginModal}
                        showInfoModal={this.showInfoModal}
                        showRegisterSuccessModal={this.showRegisterSuccessModal}
                        showResetPasswordModal={this.showResetPasswordModal}
                    />
                    <Switch>
                        <Route exact path='/'
                               render={(props) => <Homepage {...props} currentUser={this.state.currentUser}
                                                            showModal={this.showRegisterModal}
                                                            showLoginModal={this.showLoginModal}/>}
                        />
                        <Route exact path='/game/:id'
                               render={(props) => <Gamepage {...props} showLoginModal={this.showLoginModal}/>}
                        />
                        <Route exact path='/terms-and-conditions' component={TermsConditions}/>
                        <Route exact path='/privacy-policy-en' component={PrivacyPolicyEn} />
                        <Route component={NotFound}/>
                    </Switch>
                    <Footer />
                </Router>
        );
    }
}

export default App
