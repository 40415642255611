import React, {Component} from "react";
import {useState} from "react";
import {FaMinus, FaPlus} from "react-icons/fa";

const FaqTemplate = ({title, description, id}) => {
    const [toggleElement, setToggleHeader] = useState(false);
    return (
        <div className={'faq'}>
            <div className={`faq-header ${toggleElement ? 'faq-open' : ''}`}
                 onClick={() => setToggleHeader((prev) => !prev)} key={id}>
                <span><span>Q</span> {title}</span>
                <FaPlus/> <FaMinus/>
            </div>
            <div className={`faq-content ${toggleElement ? 'faq-open' : ''}`}>
                {description}
            </div>
        </div>
    );
};

const FaqContent = [
    {
        'title': 'Wie funktionieren spielautomaten? ',
        'description': 'Spielautomaten sind eines der beliebtesten Glücksspiele der Welt. Sie sind einfach zu spielen und bieten den Spielern die Chance auf hohe Gewinne. Spielautomaten funktionieren durch das Drehen einer Reihe von Walzen, die Symbole enthalten. Wenn der Spieler drei oder mehr gleiche Symbole auf einer Gewinnlinie findet, gewinnt er eine Auszahlung. '
    }, {
        'title': 'Wie gewinnt man an spielautomaten? ',
        'description': 'Spielautomaten sind ein Glücksspiel, bei dem die Spieler auf das Ergebnis der sich drehenden Symbole wetten. Es gibt viele verschiedene Möglichkeiten, an Spielautomaten zu gewinnen, aber einige grundlegende Tipps sind, bei jedem Dreh den maximal zulässigen Betrag zu setzen, progressive Jackpot-Spiele zu spielen und Bonusrunden zu Ihrem Vorteil zu nutzen. Wenn Sie diese einfachen Tipps befolgen, können Sie Ihre Gewinnchancen an Spielautomaten erhöhen.  \n',
    }, {
        'title': 'Wann geben spielautomaten am besten?  ',
        'description': 'Spielautomaten sind ein Glücksspiel, bei dem das Ergebnis durch Zufallsgeneratoren (RNGs) bestimmt wird. Das bedeutet, dass es keine bestimmte Tageszeit bzw. keinen bestimmten Tag gibt, an dem die Wahrscheinlichkeit einer Auszahlung höher ist als zu einem anderen Zeitpunkt.  \n',
    }, {
        'title': 'Welche spiele sind umsonst?',
        'description': 'Es gibt eine große Auswahl an Spielautomaten, die Sie kostenlos nutzen können. Stürzen Sie sich in das Demospiel einiger der beliebtesten Online-Spielautomaten, wie Book of Dead, Fire Joker oder Legacy of Dead. Viele Menschen genießen es, Spielautomaten online kostenlos zu spielen, denn so können sie das Spiel lernen, ohne Geld zu riskieren. ',
    }, {
        'title': 'Wie manipuliert man einen spielautomaten?  ',
        'description': 'Eine Möglichkeit, einen Online-Spielautomaten zu schlagen, besteht darin, ein Wettsystem zu verwenden. Die Tipps, die Sie online finden können, bieten jedoch keine Garantie für einen Gewinn. Ihre beste Chance besteht darin, Ihre Strategie mit der Zeit aufzubauen und sich mit den Spielautomaten, auf die Sie setzen, gut vertraut zu machen. ',
    }
];

export class Faq extends Component {
    render() {
        return (
            <div className='faq-section'>
                <h5>FAQs</h5>
                <div className={'faq-container'}>
                    {FaqContent.map((value, id) => {
                        return <FaqTemplate title={value.title} description={value.description} key={id}/>;
                    })}
                </div>
            </div>
        )
    }
}
