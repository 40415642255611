import React from 'react'
import logo from '../assets/imgs/logo-white-new.png'
import language from '../assets/language';

const Footer = () => {
    return (
        <footer>
            {/*<div className='stores'>*/}
                {/*<img src={gplayIcon} alt='googleplay' />*/}
                {/*<img src={appstoreIcon} alt='appstore' />*/}
            {/*</div>*/}
            {/*<div className='footer__logo'>*/}
                {/*<img src={logo} alt='logo'/>*/}
            {/*</div>*/}
            <div className='footer__desktop'>
                <div className='logo-container'>
                    <img className='logo' src={logo} alt='logo footer'/>
                </div>
                {/*<div className={"w-180"}>*/}
                    {/*<img src={gplayIcon} alt='googleplay' />*/}
                {/*</div>*/}
                {/*<div className={"w-180"}>*/}
                    {/*<img src={appstoreIcon} alt='appstore' />*/}
                {/*</div>*/}
            </div>
            <div className="text-center contact">{language.footer.row1}</div>
            <div className="text-center contact"> {language.footer.row2} </div>
            <a className={'color-yellow mb-2 text-decoration-none'} href="mailto:info@wunderino.social"> info@wunderino.social </a>
            <div className="privacy-policy"><a href="/privacy-policy-en">{language.footer.policy_privacy}</a>  -  <a href="/terms-and-conditions">{language.footer.terms_and_conditions}</a></div>
            <div className={'footer-copy'}>© 2022 Wunderino.social</div>
        </footer>
    )
}

export default Footer
