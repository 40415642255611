import React, {Component} from 'react'
import {Button, Modal, Form} from 'react-bootstrap'
import {FaTwitterSquare} from 'react-icons/fa'
// import UserIcon from '../../assets/imgs/user.png'

import {signInWithTwitter} from "../../firebase/firebase.utils";

class InfoModal extends Component {

    onSubmit = async e => {
        e.preventDefault();
    };

    onSubmitTwitter = () => {
        signInWithTwitter();
        this.props.onHide();
    }

    render() {
        const {setLoginModal, setRegisterModal, show, onHide} = this.props;

        return (
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-register"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <h2 className="title-modal">Register and play</h2>
                    <Form onSubmit={this.onSubmit} noValidate>
                        <Form.Group>
                            <div>新規登録またはログインをすると、すべてのゲームにアクセスすることができます。</div>
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckbox" className="text-center">
                            <Button variant="primary" type="button" className="button-register"
                                    onClick={setRegisterModal}>
                                Sign Up
                            </Button>
                            <Form.Text className="text-muted f-10">
                                既にアカウントをお持ちですか？ <a href={'#'} onClick={setLoginModal}>今すぐログイン</a>
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckbox" className="text-center mobile">
                            <Form.Text className="text-muted">
                                または下記の方法でログイン
                            </Form.Text>
                            <div className="row">
                                <div className="col-md-12 text-right">
                                    <a href='#' onClick={this.onSubmitTwitter}
                                       className="login-twitter">
                                        <span className="text-twitter">Log in with Twitter</span>
                                        <span className='i-twitter'><FaTwitterSquare/></span>
                                    </a>
                                </div>
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default InfoModal


