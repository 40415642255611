import React from 'react'
import {Helmet} from "react-helmet";
import BackButton from "../components/BackButton";
import {auth} from "../firebase/firebase.utils";
import {FaExpandArrowsAlt} from 'react-icons/fa'
import imageGame from '../assets/imgs/game1.png'
import OwlCarousel from 'react-owl-carousel';
import GameSlider from '../components/GameSlider';
import games from '../assets/social-games';
import ReactHtmlParser from 'react-html-parser';

class Gamepage extends React.Component {
    gameId;

    constructor(props) {
        super(props);

        this.gameId = props.match.params.id
        this.state = {
            gameDetails: games.find(el => el.id === this.gameId),
            errorMessage: "",
            addClass: false,
            country: '',
            url: props.location.pathname,
        }
    }
    toggle() {
        this.setState({addClass: !this.state.addClass});
    }


    owlCarouselOptions = {
        items: 8,
        margin: 20,
        stagePadding: 20,
        nav: true,
        loop  : true,
        rewind: true,
        autoplay: false,
        responsive: {
            0: {
                items: 3,
                nav: true
            },
            720: {
                items: 4,
                nav: true
            },
            1000: {
                items: 9,
                nav: true
            },
        },
    }


    componentDidMount() {
        window.scrollTo(0, 0);
    }

    showIframe = () => {
        let toggleClass = ["toggle-full"];
        if(this.state.addClass) {
            toggleClass.push('active');
        }

        return (
            <div className="container">
                <div className={"iframe-game " + toggleClass.join(' ')}>
                    <BackButton/>
                        <iframe
                            src={this.state.gameDetails.gameUrl}
                            frameBorder='0'
                            title='Wunderino'
                        ></iframe>

                    <a className={'expand-game ' + toggleClass.join(' ')} onClick={this.toggle.bind(this)}><FaExpandArrowsAlt/></a>
                </div>
            </div>
        )
    }

    showGameSlider = () => {
        window.getSelection().empty()
        return games.map((elem, index) => (
            <GameSlider
                key={index}
                image={elem.thumbnailUrl}
                // link={elem.link}
            />
        ))
    }

    signOut = () => {
        auth.signOut();
    }

    render() {

        let toggleClass = ["toggle-full"];

        if(this.state.addClass) {
            toggleClass.push('active');
        }

        let gContent = this.state.gameDetails.gameContent;

        let content = <div>
            {this.showIframe()}

            <div className='container-game-info'>
                <div className='container'>
                    <div className="row row-game mb-4">
                        <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                            {/*<img src={this.state.image}/>*/}
                            <img className="game-image" src={"https:" + this.state.gameDetails.thumbnailUrl}/>
                        </div>
                        <div className="col-12 col-sm-9 col-md-9 col-lg-9">
                            <h1 className="game-title">{this.state.gameDetails.name}</h1>
                            <div className="game-text">
                                {ReactHtmlParser(gContent)}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='container-carousel'>
                            <OwlCarousel className='owl-theme' {...this.owlCarouselOptions}>
                                {this.showGameSlider()}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>

            <Helmet>
                <title>{this.state.gameDetails.metaTitle} Wunderino</title>
                <meta name={"description"} content={this.state.gameDetails.metaDescription} />
                <link rel="canonical" href={'https://wunderino.social' + this.state.url}  />
                <meta name="robots" content="index,follow" />
                <script type="application/ld+json">
                    {JSON.stringify(this.state.gameDetails.structuredData)}
                </script>
            </Helmet>
        </div>;

        return (
            <div className="game__m__container">
                <div className={'gameplay__container ' + toggleClass.join(' ')} style={{color: 'white'}}>
                    {content}
                </div>
                <div className="row" style={{display: 'none'}}>
                    <div className='container-carousel'>
                        <OwlCarousel className='owl-theme' {...this.owlCarouselOptions}>
                            {this.showGameSlider()}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        )
    }
}

export default Gamepage
